<template>
  <fieldset :class="{ box: !isViewModeCreate }">
    <page-title
        :title="$t('page.officer.title')"
        @edit="$emit('edit')"
    >
      <p class="has-text-justified">{{ $t('page.officer.description') }}</p>
    </page-title>
    <div class="columns is-multiline">
      <div
          v-for="(director, index) in model"
          :key="index"
          class="column is-one-third-widescreen is-half-tablet is-full"
      >
        <div class="box is-height-100">
          <h3
              v-if="isViewModeCreate"
              class="is-size-4"
          >
            {{ `${director.firstname} ${director.lastname}` }}
          </h3>
          <b-field>
            <div
                v-if="isViewModeCreate"
                class="radio-flex checkbox-flex"
            >
              <b-radio
                  v-for="role in roles"
                  :key="role"
                  v-model="director.role"
                  :native-value="role"
                  :name="`officerRole${index}`"
                  @input="unsetDirectorIfOtherRoles(index)"
              >
                {{ $t(`officer.${role}`) }}
              </b-radio>
              <b-checkbox
                  v-model="director.isSecretary"
                  :disabled="roleTaken('isSecretary', index)"
                  @input="unsetDirectorIfOtherRoles(index)"
              >
                {{ $t('officer.secretary') }}
              </b-checkbox>
              <b-checkbox
                  v-model="director.isTreasurer"
                  :disabled="roleTaken('isTreasurer', index)"
                  @input="unsetDirectorIfOtherRoles(index)"
              >
                {{ $t('officer.treasurer') }}
              </b-checkbox>
              <b-checkbox
                  v-model="director.isDirector"
                  @input="unsetOtherRolesIfDirector(index)"
              >
                {{ $t('officer.director') }}
              </b-checkbox>
            </div>
            <div v-else>
              <h4>{{ `${director.firstname} ${director.lastname}` }}</h4>
              <b-taglist>
                <b-tag
                    v-if="director.role"
                    type="is-blue is-outlined"
                    size="is-medium"
                >
                  {{ $t(`officer.${director.role}`) }}
                </b-tag>
                <b-tag
                    v-if="director.isSecretary"
                    type="is-blue is-outlined"
                    size="is-medium"
                >
                  {{ $t('officer.secretary') }}
                </b-tag>
                <b-tag
                    v-if="director.isTreasurer"
                    type="is-blue is-outlined"
                    size="is-medium"
                >
                  {{ $t('officer.treasurer') }}
                </b-tag>
                <b-tag
                    v-if="director.isDirector"
                    type="is-blue is-outlined"
                    size="is-medium"
                >
                  {{ $t('officer.director') }}
                </b-tag>
              </b-taglist>
            </div>
          </b-field>
        </div>
      </div>
    </div>
    <b-message
        v-if="isMissingAnOfficer"
        type="is-danger"
    >
      {{ $t('page.officer.missingOfficer') }}
    </b-message>
    <b-message
        v-if="hasEveryoneARole"
        type="is-danger"
    >
      {{ $t('page.officer.everyoneNeedsARole') }}
    </b-message>
  </fieldset>
</template>

<script>
  import page from '@/mixins/page'
  import cloneDeep from 'lodash-es/cloneDeep'

  export default {
    name: 'PageOfficer',
    mixins: [
      page('director') // Officer uses data from Director
    ],
    computed: {
      roles () {
        const isCopresident = this.model
            .filter(d => ['president', 'copresident'].includes(d.role))
            .length > 1
        const presidentKey = isCopresident ? 'copresident' : 'president'

        this.model
            .filter(d => ['president', 'copresident'].includes(d.role))
            .forEach(d => d.role = presidentKey)

        return [
          presidentKey,
          'vicepresident'
        ]
      },
      isMissingAnOfficer () {
        return this.errors.includes('missingOfficer')
      },
      hasEveryoneARole () {
        return this.errors.includes('everyoneNeedsARole')
      }
    },
    methods: {
      roleTaken (role, index) {
        return !!this.model
            .filter((d, i) => (d[role] || d.role === role) && i !== index)
            .length
      },
      unsetDirectorIfOtherRoles (index) {
        const director = this.model[index]
        if (director.isDirector && (director.isSecretary || director.isTreasurer || director.role !== '')) {
          director.isDirector = false
        }
      },
      unsetOtherRolesIfDirector (index) {
        const director = this.model[index]
        if (director.isDirector) {
          director.isSecretary = false
          director.isTreasurer = false
          director.role = ''
        }
      },
      updateModelPartially () {
        const storedModelCopy = cloneDeep(this.storedModel['director'])
        this.model.forEach((director, index) => {
          ['role', 'isDirector', 'isSecretary', 'isTreasurer'].forEach((prop) => {
            storedModelCopy[index][prop] = director[prop]
          })
        })
        this.model = storedModelCopy
      },
      validate () {
        this.validateMissingOfficer()
        this.validateEveryoneNeedsARole()
      },
      validateMissingOfficer () {
        const hasPresident = this.roleTaken('president') || this.roleTaken('copresident')
        const hasSecretary = this.roleTaken('isSecretary')

        if (!hasPresident || !hasSecretary)
          this.addError('missingOfficer')
        else
          this.removeError('missingOfficer')
      },
      validateEveryoneNeedsARole () {
        if (this.model.filter((d) => !d.isSecretary && !d.isTreasurer && !d.isDirector && d.role === '').length)
          this.addError('everyoneNeedsARole')
        else
          this.removeError('everyoneNeedsARole')
      }
    }
  }
</script>
